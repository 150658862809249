.nav-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 1.5em;
	padding-top: 70px;
	height: 30px;
}

.name {
	letter-spacing: 2px;
}

.nav {
	background: white;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	word-spacing: 100px;
	font-size: 1.05rem;
	font-weight: 400;
	gap: 30px;
	cursor: pointer;
}

nav a:hover {
	border-bottom: 3px solid rgb(0, 0, 0);
}

.nav-link {
	text-decoration: none;
	color: black
}