.App {
  min-height: 100vh;

}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro', sans-serif;
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  letter-spacing: 4px;
  padding-top: 50px;
  color: #656565;
}

.sub-title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  letter-spacing: 2px;
  color: #656565;
  margin-top: 40px;
}

.big-title {
  font-size: 20px;
  line-height: 40px;
  color: #121212;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 60%;
}

.main-container {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 6%;
  padding-bottom: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.work-title {
  font-size: 45px;
  line-height: 80px;
  color: #121212;
  font-family: 'DM Serif Display', serif;
  display: flex;
  align-items: flex-start;
}

.project-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
}

.image-frame {
  width: 60%;
}

.work-row {
  display: flex;
  justify-content: space-between;
}

.text-container {
  width: 650px;
}

@media (max-width: 600px) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .big-title {
    font-size: 17px;
    margin-top: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .text-container {
    max-width: 70%;
  }

  .image-frame {
    width: 100%;
  }

  .work-row {
    flex-direction: column;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.memo-root-1 {
  position: sticky;
}