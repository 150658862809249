.footer {
  flex-shrink: 0;
  text-align: center;
  margin-top: 50px;
  font-size: 1px;
}

.p-footer {
  font-size: 12px;
  line-height: 26px;
  font-weight: 400;
  color: #656565;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .p-footer {
    font-size: 10px;
  }
}