.about-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
}

.about-title {
  font-size: 45px;
  line-height: 80px;
  color: #121212;
  font-family: 'DM Serif Display', serif;
}

.about-desc {
  font-size: 14px;
  width: 100%;
  line-height: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.desc-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
}

.skillset {
  line-height: 40px;
  color: #b0a3a3;
  font-weight: 600;
}

.profile-pic-container {
  display: flex;
  justify-content: flex-end;
}

.profile-pic {
  max-width: 80%;
  position: relative;
}

.pics-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pics {
  display: flex;
  width: 100%
}

@media (max-width: 600px) {
  .about-container {
flex-direction: column-reverse;
  }

  .skillset {
    font-size: 14px;
  }

  .profile-pic {
    width: 100%;
    position: relative;
    margin-top: 70px;
  }

  .profile-pic-container {
    justify-content: center;
  }
}