.about {
  flex-direction: column;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: 12px;
  font-weight: 500;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button-row {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
}

.project-title {
  font-size: 45px;
  line-height: 80px;
  color: #121212;
  margin: 0;
  font-family: 'DM Serif Display', serif;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-row {
    gap: 10px;
  }
}

.project-image {
  flex-basis: 50%;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
 position: relative;
}

.project-frame:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 10px 0px rgba(124, 124, 124, 0.25);
}

.project-frame {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 60px;
  transition: transform ease 0.5s, box-shadow ease 0.5s;
}

.projects-title {
  font-family: "classica-pro", serif;
  font-size: 36px;
  font-weight: 700;
  color: #121212;
}

.projects-stack, .projects-desc {
  font-size: 15px;
  color: #656565;
  font-weight: 600;
}

.project-year {
  font-size: 13px;
  color: #656565;
}

.section-projects {
  background-color: #F2F3F5;
}

.desc-frame {
  padding: 5%;
}

img {
  display: block;
  max-width: 100%;
  max-height: auto;
}

.github-logo {
  width: 15%;
  padding: 10pt;
  margin-left: auto;
  margin-right: auto;
}

.desc {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #656565;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .row {
    gap: 300px;
  }

  .button-row {
    margin: 0;
    padding: 0;
  }

  .project-frame {
    height: 100%;
    flex-direction: column;
  }
}