.button-row-project {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
}


@media (max-width: 600px) {
  .button-row-project {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }
}