.arrow {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.arrow svg {
  transition: fill 0.3s, filter 0.3s;
}

.arrow:hover svg {
  fill: rgb(137, 137, 137);
}